import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
import { Link } from "gatsby";
import { IMPACT_URL, BLOG_HOME_URL } from "../constants/pageUrls";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`It's April again, which means another celebration of `}<a parentName="p" {...{
        "href": "https://www.usa.gov/flec"
      }}>{`Financial Capability Month`}</a>{` and everything that we do at PayPerks for our financial partners, including PayPerks for Direct Express.`}</p>
    <p>{`After the success of our Ray and Dawn profile videos, our partners at Comerica & Mastercard engaged us to meet with and interview more Direct Express cardholders & PayPerks for Direct Express users.  We found no shortage of individuals willing to share their amazing stories with us, and this month, we highlight Helen below and Don `}<Link to={`${BLOG_HOME_URL}/2020-04-meet-don-payperks-for-direct-express-profile`} mdxType="Link">{`here`}</Link>{`.`}</p>
    <p>{`Meet Helen who lives just east of Los Angeles, California.  Helen suffered a tragic accident when she fell and injured her hands just months before graduating from cosmetology school.  She had taken out student loans to embark on this education, which made her injury financially problematic.`}</p>
    <p>{`Our team spent a half a day with Helen in her home in September 2019, and from this time we produced three clips to tell her story.  Part 1 focuses on the impact of the Direct Express card itself, specifically the benefits of having direct deposit over receiving checks.  Part 2 and 3 focus on PayPerks for Direct Express and the impact and considerations of our financial education.  We'll see how Helen values the knowledge she learned from our solution and the impact it made on her life.`}</p>
    <p><strong parentName="p">{`Part 1: Convenience, Safety, & Security with Direct Express`}</strong></p>
    <div className="player-frame"><iframe width="560" height="315" src="https://www.youtube.com/embed/Wssea1xQJBE" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
    <p><strong parentName="p">{`Part 2: Financial Capability with PayPerks`}</strong></p>
    <div className="player-frame"><iframe width="560" height="315" src="https://www.youtube.com/embed/SK6ngqjyp8E" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
    <p><strong parentName="p">{`Part 3: Living with a Disability & Financial Education w/ PayPerks`}</strong></p>
    <div className="player-frame"><iframe width="560" height="315" src="https://www.youtube.com/embed/wDuMg-_vpsg" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
    <p><em parentName="p">{`All of our user impact videos are available on our `}<Link to={IMPACT_URL} mdxType="Link">{`Impact`}</Link>{` page.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      